<template>
  <v-sheet class="fluid-window back-img">
    <div class="transparent content-top-padding">
      <h1 class="white--text text-center client-title-div mb-8">
        <i18n path="detail.shop" />
      </h1>
    </div>
    <v-divider dark></v-divider>
    <v-stepper v-model="e1" dark class="transparent mx-2" flat>
      <v-stepper-header class="stepper-header">
        <template v-for="(step, i, ind) in $t('shop.steps')">
          <v-stepper-step :complete="e1 > (ind+1)" :step="i" :key="'stpr-'+i">
            <span>{{step.short}}</span>
          </v-stepper-step>
          <v-divider :key="'dvd-'+i" v-if="ind<3"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row justify="center" align-content="space-between" align="center">
            <v-col cols="12" class="text-center">
              <h2>
                <i18n path="shop.steps.2.title"></i18n>
              </h2>
            </v-col>
            <v-col v-for="p in products" :key="p.idx" md="3" cols="12">
              <v-card
                class="ma-2 product-card hover-enable rounded-xl"
                @click.stop="select_product(p)"
              >
                <v-card-title class="d-block text-center word-wrap">{{p.name}}</v-card-title>
                <v-card-text>
                  <v-divider dark class="my-2"></v-divider>
                  <div v-html="p.description"></div>
                  <v-divider dark class="my-2"></v-divider>
                  <div class="services-title">
                    <i18n path="shop.services" />
                  </div>
                  <div class="text-center d-flex">
                    <service-chip v-for="s in p.services_obj" :key="s.idx" class="mx-1 service-chip" :item="s" :sett="p.settings"/>
                    <!-- <span
                      v-for="s in p.services_obj"
                      :key="s.idx"
                      class="mx-1 service-chip"
                    >{{s.name}}</span> -->
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn @click="e1=1" text dark class="error--text">
                <i18n path="shop.cancel" />
                <v-icon right>mdi-cancel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-row justify="center" align-content="space-between" align="center" v-if="product">
            <v-col cols="12" class="text-center">
              <h2>
                <i18n path="shop.steps.3.title"></i18n>
              </h2>
            </v-col>
            <v-col v-for="t in product.tariffs_obj" :key="t.idx" cols="12" md="3">
              <v-card class="ma-2 tariff-card hover-enable rounded-xl" @click="select_tariff(t)">
                <v-card-title class="d-block text-center word-wrap">{{t.name}}</v-card-title>
                <v-card-text>
                  <div class="services-title">
                    <i18n path="shop.details" />
                  </div>
                  <div>
                    <WeekForTime :days="t.time.days" />
                    <div>{{t.time.hours | time_interval}}</div>
                  </div>
                </v-card-text>
                <v-card-text class="text-center price-class mb-2">{{t.price | currency}}</v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn @click="e1=1" text dark class="error--text">
                <i18n path="shop.cancel" />
                <v-icon color="error" right>mdi-cancel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12" class="text-center">
              <h2>
                <i18n path="shop.steps.4.title"></i18n>
              </h2>
            </v-col>
            <v-col md="4" cols="12">
              <v-card dark class="transparent">
                <v-card-title class="d-block text-center">
                  <span>
                    <i18n path="shop.clinfo" />
                  </span>
                </v-card-title>
                <v-card-text>
                  <table style="width: 100%;">
                    <tr>
                      <th class="text-right">
                        <i18n path="shop.name" />
                      </th>
                      <td align="right">{{client.name}}</td>
                    </tr>
                    <tr>
                      <th class="text-right">
                        <i18n path="email" />
                      </th>
                      <td align="right">{{client.email}}</td>
                    </tr>
                    <tr>
                      <th class="text-right">
                        <i18n path="phone" />
                      </th>
                      <td align="right">{{client.phone}}</td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="5" cols="12">
              <v-card v-if="tariff" class="transparent">
                <v-card-title>
                  <i18n path="shop.purchinfo" />
                </v-card-title>
                <v-card-text>
                  <div class="main-product-header">
                    <i18n path="shop.abonement" />
                  </div>
                  <div class="ma-2">
                    <span>{{product.name}}</span>
                  </div>
                  <div class="subtitle-2">
                    <i18n path="shop.details" />
                  </div>
                  <div class="ma-2">{{product.description}}</div>
                  <div class="subtitle-2">
                    <i18n path="shop.services" />
                  </div>
                  <div class="mx-1 my-3">
                    <span
                      v-for="s in product.services_obj"
                      :key="s.idx"
                      class="mx-1 service-chip"
                    >{{s.name}}</span>
                  </div>
                  <div class="main-product-header">
                    <i18n path="shop.tariff" />
                  </div>
                  <div class="ma-2">{{tariff.name}}</div>
                  <div class="subtitle-2">
                    <i18n path="shop.time" />
                  </div>
                  <div class="ma-2">
                    <WeekForTime :days="tariff.time.days" />
                    <div>{{tariff.time.hours | time_interval}}</div>
                  </div>
                  <div class="text-right price-class mb-2">
                    <i18n path="shop.total">
                      <template #price>{{tariff.price | currency}}</template>
                    </i18n>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" align-self="end" class="text-center">
              <v-btn class="ok-button teal mb-5 mr-5" outlined rounded dark @click="startBuy">
                <div>
                  <div>
                    <v-img src="/img/Pay_By2.png"></v-img>
                  </div>
                  <div class="mt-4 text-h5 font-weight-black">
                    <i18n path="shop.pay" />
                  </div>
                </div>
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn @click="e1=1" text dark class="error--text">
                <i18n path="shop.cancel" />
                <v-icon color="error" right>mdi-cancel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="mb-12 transparent" height="200px">
            <v-card-text>
              <div v-if="result==2" class="result-div success--text">
                <i18n path="shop.ok" />
              </div>
              <div v-else class="result-div error--text">
                <i18n path="shop.bad" />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text dark @click="startNew">
                <i18n path="shop.start" />
                <v-icon color="error" right>mdi-arrow-u-left-top-bold</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-sheet>
</template>

<script>
export default {
  name: "Shop",
  components: {
    WeekForTime: () => import("../components/WeekForTime.vue"),
    "service-chip": () => import("../components/ServiceChip.vue")
  },
  computed: {
    client() {
      return this.$store.getters["session/client"];
    },
    products() {
      return this.abonements;
    },
  },
  methods: {
    select_type(tp) {
      this.type = tp;
      this.e1 = 2;
    },
    select_product(p) {
      this.product = { ...p };
      this.e1 = 2;
    },
    select_tariff(t) {
      this.tariff = { ...t };
      this.e1 = 3;
    },
    startNew() {
      this.$api.getShopProducts().then((r) => {
        this.shop = { ...r };
        this.collectAbonement();
        this.product = null;
        this.tariff = null;
        this.type = null;
        this.$router.push("/shop");
        this.e1 = 1;
      });
    },
    collectAbonement() {
      this.shop.a.forEach((a) => {
        a.services_obj = [];
        a.tariffs_obj = [];
        if (a.description) {
          a.description = a.description.replace(/\n/gm, "<br />");
        }
        a.services.split(",").forEach((e) => {
          const toadd = this.shop.s.find((o) => o.idx == e);
          if (toadd) a.services_obj.push(toadd);
        });
        a.tariffs.split(",").forEach((e) => {
          const toadd = this.shop.t.find((o) => o.idx == e && o.webtype);
          if (toadd) a.tariffs_obj.push(toadd);
        });
        if (!this.abonements.find((o) => o.idx == a.idx))
          this.abonements.push(a);
      });
    },
    collectServices() {
      this.shop.s.forEach((a) => {
        a.tariffs_obj = [];
        a.tariffs.split(",").forEach((e) => {
          const toadd = this.shop.t.find((o) => o.idx == e && o.webtype);
          if (toadd) a.tariffs_obj.push(toadd);
        });
        if (a.tariffs_obj.length == 0) return;
        if (!this.services.find((o) => o.idx == a.idx)) this.services.push(a);
      });
    },
    startBuy() {
      delete this.product.services_obj; 
      delete this.product.tariffs_obj; 
      this.$api
        .getBuyLink({
          type: this.type,
          product: this.product,
          tariff: this.tariff,
          price: this.tariff.price,
          details: {
            email: this.$store.getters["session/email"],
            phone: this.$store.getters["session/phone"]
          }
        })
        .then((r) => {
          if (r) location.replace(r);
        });
    },
  },
  mounted() {
    if (this.$route.query.o) {
      this.$api
        .getShopOrder(this.$route.query.o, this.$store.getters["session/cid"])
        .then((r) => {
          this.result = r;
          this.e1 = 4;
        });
    } else {
      this.$api.getShopProducts().then((r) => {
        this.shop = { ...r };
        this.collectAbonement();
        //this.collectServices();
      });
    }
  },
  data() {
    return {
      e1: 1,
      type: 1,
      result: 0,
      product: null,
      tariff: null,
      services: [],
      abonements: [],
    };
  },
};
</script>

<style scoped>
.result-div {
  font-size: 64px;
  font-weight: 600;
  text-align: center;
  margin-top: 80px;
}
.main-product-header {
  text-decoration: underline;
}
.back-img {
  background-image: url("/img/about.jpg");
  background-position: center;
  background-size: cover;
}
.type-card {
  box-shadow: 5px 5px 15px 5px #90a19a !important;
  -webkit-box-shadow: 5px 5px 15px 5px #90a19a !important;
  background-color: #535653 !important;
  border: 2px solid yellow;
}
.type-card:hover {
  box-shadow: 2px 2px 15px 1px #90a19a !important;
  -webkit-box-shadow: 2px 2px 15px 1px #90a19a !important;
  background-color: #6d706d !important;
  border: 1px solid yellow;
}
.product-card {
  box-shadow: 5px 5px 15px 5px #90a19a !important;
  -webkit-box-shadow: 5px 5px 15px 5px #90a19a !important;
  background-color: #535653 !important;
  border: 2px solid springgreen;
}
.product-card.hover-enable:hover {
  box-shadow: 2px 2px 15px 1px #90a19a !important;
  -webkit-box-shadow: 2px 2px 15px 1px #90a19a !important;
  background-color: #6d706d !important;
  border: 1px solid springgreen;
}
.tariff-card {
  box-shadow: 5px 5px 15px 5px #90a19a !important;
  -webkit-box-shadow: 5px 5px 15px 5px #90a19a !important;
  background-color: #535653 !important;
  border: 2px solid deepskyblue;
}
.tariff-card.hover-enable:hover {
  box-shadow: 2px 2px 15px 1px #90a19a !important;
  -webkit-box-shadow: 2px 2px 15px 1px #90a19a !important;
  background-color: #6d706d !important;
  border: 1px solid deepskyblue;
}
.stepper-header {
  background-color: #363635;
}
.services-title {
  font-size: 12px;
  font-style: oblique;
  color: darkgrey;
}
.service-chip {
  background-color: #707070 !important;
  padding: 8px;
  border: 1px solid;
  border-radius: 10px;
}
.price-class {
  font-size: 3rem !important;
  color: greenyellow !important;
  font-weight: 600;
}
.ok-button {
  /* width: 140px; */
  box-shadow: 5px 5px 15px 5px #90a19a !important;
  -webkit-box-shadow: 5px 5px 15px 5px #90a19a !important;
  background-color: #535653 !important;
  border: 2px solid springgreen;
  height: 140px !important;
  /* margin-left: 100px;
  margin-top: 15px; */
}
.ok-icon {
  font-size: 100px !important;
}
.cancel-text {
  font-size: 35px;
}
</style>